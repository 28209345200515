import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IHttpOptions } from 'src/app/shared/models';
import { CommonUtils } from 'src/app/shared/utils/common.utils';
import { ApiEndpoints } from './api-endpoints';

@Injectable({
  providedIn: 'root',
})
export class ProcessControllerMicroService {
  constructor(private http: HttpClient) {}

  getJobDetails<T>(jobId: string, options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(`${ApiEndpoints.GET_JOB}${jobId}`, options);
  }

  getJobDetailsForBreadCrumb<T>(jobId: string, options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(`${ApiEndpoints.GET_JOB_BREADCRUMB}${jobId}`, options);
  }

  getJobList<T>(pageData: any, options?: IHttpOptions): Observable<T> {
    const pageDetails: string = CommonUtils.prepareParams(pageData);
    return this.http.get<T>(`${ApiEndpoints.GET_JOB_LIST}?${pageDetails}`, options);
  }

  getJobStats<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(`${ApiEndpoints.GET_JOB_STATS}`, options);
  }

  getExtractionSchema<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_EXTRACTION_SCHEMA, options);
  }

  getExtractionSchemaByID<T>(id: string, options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_EXTRACTION_SCHEMA + '/' + id, options);
  }

  createJob<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.CREATE_JOB, body, options);
  }

  createAndExecuteAutoJobs<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.CREATE_AND_EXECUTE_AUTO_JOBS, body, options);
  }

  updateJob<T>(jobId: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.patch<T>(`${ApiEndpoints.GET_JOB}${jobId}`, body, options);
  }

  getCategoryList<T>(): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_CATEGORY_LIST);
  }

  createExtractionSchema<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.CREATE_EXTRACTION_SCHEMA, body, options);
  }

  updateExtractionSchema<T>(id: string, body: any, options?: IHttpOptions): Observable<T> {
    return this.http.patch<T>(
      CommonUtils.getReplacedEndpoint(ApiEndpoints.UPDATE_EXTRACTION_SCHEMA, id),
      body,
      options,
    );
  }

  startJob<T>(jobId: number): Observable<T> {
    return this.http.post<T>(CommonUtils.getReplacedEndpoint(ApiEndpoints.START_JOB, jobId), {});
  }

  rerunSelectedFileIds<T>(jobId: number, jobRunId, params): Observable<T> {
    return this.http.post<T>(
      ApiEndpoints.RERUN_FILES + jobId + '/' + jobRunId,
      {},
      {
        params,
      },
    );
  }

  startExtractionManually<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.START_EXTRACTION_MANUALLY, body, options);
  }

  getJobRunProgress<T>(jobRunId: number, options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(CommonUtils.getReplacedEndpoint(ApiEndpoints.GET_JOB_RUN_PROCESS, jobRunId), options);
  }

  getExtractedDataInfo<T>(options?: IHttpOptions): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_EXTRACTED_DATA_INFO, options);
  }

  // admin related functions
  getPagesQueuedUp<T>(): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_MESSAGE_COUNT);
  }

  getTemplatePagesQueuedUp<T>(): Observable<T> {
    return this.http.get<T>(ApiEndpoints.GET_TEMPLATE_MESSAGE_COUNT);
  }

  spawnECSTasks<T>(body: any): Observable<T> {
    return this.http.post<T>(ApiEndpoints.SPAWN_TASKS, body);
  }

  // tests module service
  createExecuteDuplicateJobs<T>(body: any): Observable<T> {
    const options: IHttpOptions = CommonUtils.noCachingOfAPI({});
    return this.http.post<T>(ApiEndpoints.CREATE_TESTS_MODULE_DUPLICATE_JOB, body, options);
  }

  listDuplicateJobs<T>(testId, category): Observable<T> {
    const url = `${ApiEndpoints.LIST_DUPLICATE_JOBS}?category=${category}`;
    if (+testId) {
      var options: IHttpOptions = CommonUtils.noCachingOfAPI({
        id: testId,
      });
    } else {
      var options: IHttpOptions = CommonUtils.noCachingOfAPI({});
    }
    return this.http.get<T>(url, options);
  }

  startComparisonWithMasterData<T>(eventNums: string): Observable<T> {
    const options: IHttpOptions = CommonUtils.noCachingOfAPI({});
    const body = { event_num: eventNums };
    return this.http.post<T>(ApiEndpoints.START_COMPARISON_WITH_MASTER_DATA, body, options);
  }

  deleteJob(jobId: number): Observable<any> {
    return this.http.delete(ApiEndpoints.DELETE_JOB + jobId);
  }

  downloadBlankTemplate(templateFileName: string): Observable<any> {
    return this.http.get(ApiEndpoints.DOWNLOAD_BLANK_TEMPLATE + templateFileName, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  deleteExtractionSchema(schemaId: number): Observable<any> {
    return this.http.delete(ApiEndpoints.DELETE_EXTRACTION_SCHEMA + schemaId);
  }

  createBatchJobs<T>(body: any, options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(ApiEndpoints.CREATE_BATCH_JOBS, body, options);
  }

  getPresignedUrlForMissingTempUpload<T>(fileNames: string[], options?: IHttpOptions): Observable<T> {
    return this.http.post<T>(
      ApiEndpoints.GET_PRESIGNED_UPLOAD_URL_BLANK_TEMP,
      {
        fileNames,
      },
      options,
    );
  }

  getDownloadSchemaReport(id): Observable<any> {
    return this.http.get(`${ApiEndpoints.DOWNLOAD_SCHEMA_REPORT}/${id}`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params: {
        id,
      },
    });
  }

  getUnusedTils(schemaID) {
    return this.http.get(CommonUtils.getReplacedEndpoint(ApiEndpoints.GET_UNUSED_TILS, schemaID));
  }

  getNotifications() {
    return this.http.get(ApiEndpoints.GET_NOTIFICATIONS, CommonUtils.noCachingOfAPI());
  }

  deleteNotificationById(id: number) {
    return this.http.delete(ApiEndpoints.DELETE_NOTIFICATION + id, CommonUtils.noCachingOfAPI());
  }

  updateNotificationById(id: number, payload) {
    return this.http.patch(ApiEndpoints.UPDATE_NOTIFICATION + id, payload, CommonUtils.noCachingOfAPI());
  }
  
  updateJobRun(id: number, payload) {
    let url: string = CommonUtils.getReplacedEndpoint(
      ApiEndpoints.UPDATE_JOB_RUN,
      id
    );
    return this.http.put(
      url,
      payload,
      CommonUtils.noCachingOfAPI()
    );
  }

  updateJobRunFiles(id: number, payload) {
    let url: string = CommonUtils.getReplacedEndpoint(
      ApiEndpoints.UPDATE_JOB_RUN_FILE,
      id
    );
    return this.http.put(
      url,
      payload,
      CommonUtils.noCachingOfAPI()
    );
  }
  
}


