import { Injectable, Renderer2, TemplateRef } from '@angular/core';
import { DisclaimerComponent } from '../../feature/disclaimer.component';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from './auth/auth.service';
import { DialogService } from './dialog/dialog.service';
import { CookiesUtil } from 'src/app/shared/utils/cookie.util';
@Injectable()
export class DisclaimerService {
  component: DisclaimerComponent;
  template: TemplateRef<any>;
  dialogRef: any;
  constructor(
    private authService: AuthService,
    public dialogService: DialogService,
    private renderer: Renderer2,
  ) {}
  getDisclaimer() {
    return CookiesUtil.get('disclaimer');
  }

  showDisclaimer(template: TemplateRef<any>, isLoggedIn?: boolean): MatDialogRef<any, any> {
    this.template = template;
    if (!this.getDisclaimer() && (this.authService.isAuthenticated() || isLoggedIn)) return this.showDisclaimerPopup();
    return null;
  }

  showDisclaimerPopup(): MatDialogRef<any, any> {
    return this.dialogService.openCustomTemplate(this.template, {
      disableClose: true,
      closeOnNavigation: false,
      hasBackdrop: true,
    });
  }

  afterDisclaimerPopped(component) {
    const containerElement = this.checkCordinatesToShowAgreeButton(component);
    this.renderer.listen(containerElement, 'scroll', (e) => {
      this.checkCordinatesToShowAgreeButton(component);
    });
  }

  checkCordinatesToShowAgreeButton(component: DisclaimerComponent) {
    const options: IntersectionObserverInit = {
      root: component.contentContainer.nativeElement,
      rootMargin: '0px',
      threshold: 1.0,
    };
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          component.showAgreeButton = true;
          observer.disconnect();
        }
      });
    }, options);
    observer.observe(component.disclaimerRef.nativeElement);
    return component.contentContainer.nativeElement;
  }
}
