import { DOCUMENT, Location } from '@angular/common';
import { EventEmitter, Inject, Injectable, WritableSignal, computed, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  BrowserConfigurationAuthError,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  StringUtils,
  UrlString,
} from '@azure/msal-browser';
import { EMPTY, Observable, catchError, filter, of, switchMap, take } from 'rxjs';
import { AuthorisationRoles } from 'src/app/shared/enums/roles.enum';
import { IModulePrivileges } from 'src/app/shared/models/privileges.model';
import { CookiesUtil } from 'src/app/shared/utils/cookie.util';
import { SnackbarService } from '../snackbar/snackbar.service';
import { PRIVILEGES } from './privileges';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  private privileges: IModulePrivileges = {} as IModulePrivileges;
  timeEmitter = new EventEmitter<number>();
  authResult: AuthenticationResult;
  isAdmin: boolean;
  private loginSignal: WritableSignal<boolean> = signal(false);
  loginComputed = computed(() => this.loginSignal());
  isReadOnlyUser: boolean;
  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private msalInterceptorConfig: MsalInterceptorConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document,
    private location: Location,
    private snackbar: SnackbarService,
  ) {}

  checkIfUserLoggedIn() {
    if (this.isAuthenticated()) {
      this.isLoggedIn = true;
      this.setPrivileges();
      return true;
    } else {
      this.isLoggedIn = false;
      return false;
    }
  }

  isAccountExpired(account) {
    return account && new Date(account.expiresOn).getTime() / 1000 < new Date().getTime() / 1000;
  }

  isIdTokenExpired() {
    const activeAccount: AccountInfo = this.getActiveAccount();
    return activeAccount?.idTokenClaims?.exp && activeAccount?.idTokenClaims?.exp < new Date().getTime() / 1000;
  }

  setPrivileges(): void {
    try {
      const roles: string[] = this.getActiveAccount()?.idTokenClaims.roles;
      const isAdmin: boolean = roles.includes(AuthorisationRoles.ADMIN);
      const isSuperUser: boolean = roles.includes(AuthorisationRoles.SUPERUSERS);
      const isReadOnlyUser: boolean = roles.includes(AuthorisationRoles.DATA_CONSUMERS);
      if (isAdmin) {
        this.privileges = PRIVILEGES.isAdmin;
        this.isAdmin = true;
      } else if (isSuperUser) this.privileges = PRIVILEGES.isSuperUser;
      else {
        this.privileges = PRIVILEGES.isReadOnlyUser;
        this.isReadOnlyUser = true;
      }
    } catch {
      this.privileges = PRIVILEGES.isReadOnlyUser;
    }
  }

  setActiveAccount(authenticationResult: AuthenticationResult) {
    this.isLoggedIn = true;
    this.authResult = authenticationResult;
    this.msalService.instance.setActiveAccount(authenticationResult.account);
  }

  getPrivileges(): IModulePrivileges {
    return this.privileges;
  }

  hasAdminAccess(): boolean {
    return this.isAdmin;
  }

  getActiveAccount(): AccountInfo {
    return (
      this.msalService.instance.getActiveAccount() ||
      (this.msalService.instance.getAllAccounts().length ? this.msalService.instance.getAllAccounts()[0] : null)
    );
  }

  getAccountInfo(): AccountInfo {
    // Sets account as active account or first account
    let account: AccountInfo;
    if (this.msalService.instance.getActiveAccount()) account = this.msalService.instance.getActiveAccount();
    else account = this.msalService.instance.getAllAccounts()[0];

    return account;
  }

  getAuthRequest(account, req) {
    return typeof this.msalInterceptorConfig.authRequest === 'function'
      ? this.msalInterceptorConfig.authRequest(this.msalService, req, {
          account,
        })
      : { ...this.msalInterceptorConfig.authRequest, account };
  }

  isAuthenticated(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    CookiesUtil.deleteAll();
    this.msalService.logoutRedirect({
      // postLogoutRedirectUri: 'http://localhost:4200/login',
    });
  }

  getAccessToken(): string {
    return this.authResult.accessToken;
  }

  getIdToken(): string {
    return this.authResult.idToken;
  }

  getUserName(): string {
    return this.getActiveAccount().name;
  }

  getGID(): string {
    return this.getActiveAccount().idTokenClaims.SCGID as string;
  }

  redirectObservable() {
    this.msalService.handleRedirectObservable().subscribe({
      next: (result) => {
        if (result !== null && result.account !== null) {
          this.msalService.instance.setActiveAccount(result.account);
          this.router.navigate(['/home']); // Redirect to the home page after successful login
        }
      },
      error: (error) => {
        console.error('Redirect error:', error);
      },
    });
  }

  authenticationSubjectSubscription() {
    this.msalBroadcastService.msalSubject$.pipe().subscribe((eventMsg: EventMessage) => {
      if (eventMsg.eventType === EventType.ACQUIRE_TOKEN_NETWORK_START) {
        this.snackbar.snackBarWarning('Session Expired: Acquiring token. May take few seconds');
        return;
      }

      if (this.isLoginSuccess(eventMsg)) this.loginSucceeded(eventMsg);

      if (eventMsg.eventType === EventType.ACQUIRE_TOKEN_FAILURE || eventMsg.eventType === EventType.SSO_SILENT_FAILURE)
        if (this.isAccountExpired(eventMsg.payload['account']) || this.isIdTokenExpired()) {
          this.snackbar.snackBarWarning('Session Expired: Couldnt acquire token, Please login again');
          this.logout();
        }
    });
  }

  isLoginSuccess(event: EventMessage) {
    return event.eventType === EventType.LOGIN_SUCCESS;
  }

  loginSucceeded(event: EventMessage) {
    const authenticationResult = event.payload as AuthenticationResult;
    if (authenticationResult) this.setActiveAccount(authenticationResult);
    this.loginSignal.set(true);
    this.setPrivileges();
    this.router.navigate(['']);
  }

  /**
   * Try to acquire token silently. Invoke interaction if acquireTokenSilent rejected with error or resolved with null access token
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @param account Account
   * @returns Authentication result
   */
  acquireToken(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[],
    account: AccountInfo,
  ): Observable<AuthenticationResult> {
    return this.msalService.acquireTokenSilent({ ...authRequest, scopes, account }).pipe(
      catchError(() => {
        return this.msalBroadcastService.inProgress$.pipe(
          take(1),
          switchMap((status: InteractionStatus) => {
            // this.snackbar.snackBarDanger('Session expired: Retrying to fetch token - Please wait');
            if (status === InteractionStatus.None) return this.acquireTokenInteractively(authRequest, scopes);

            return this.inProgressObservable().pipe(switchMap(() => this.acquireToken(authRequest, scopes, account)));
          }),
        );
      }),
      switchMap((result: AuthenticationResult) => {
        this.msalService.instance.setActiveAccount(result.account);
        if ((result?.idTokenClaims as any).exp && (result?.idTokenClaims as any).exp < new Date().getTime() / 1000) {
          // this.snackbar.snackBarDanger('Session expired: Retrying to fetch token - Please wait');
          return this.inProgressObservable().pipe(
            switchMap((): Observable<AuthenticationResult> => this.acquireTokenInteractively(authRequest, scopes)),
          );
        }

        if (!result.accessToken) {
          return this.inProgressObservable().pipe(
            switchMap((): Observable<AuthenticationResult> => this.acquireTokenInteractively(authRequest, scopes)),
          );
        }
        return of(result);
      }),
    );
  }

  inProgressObservable(): Observable<InteractionStatus> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus): boolean => status === InteractionStatus.None),
      take(1),
    );
  }

  /**
   * Invoke interaction for the given set of scopes
   * @param authRequest Request
   * @param scopes Array of scopes for the request
   * @returns Result from the interactive request
   */
  private acquireTokenInteractively(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[],
  ): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      return this.msalService.acquireTokenPopup({ ...authRequest, scopes });
    }
    this.snackbar.snackBarDanger('Session expired: Retrying to fetch token - Please wait', 10000);

    const redirectStartPage: string = window.location.href;
    this.msalService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage,
    });
    return EMPTY;
  }

  checkInteractionType = () => {
    if (
      this.msalInterceptorConfig.interactionType !== InteractionType.Popup &&
      this.msalInterceptorConfig.interactionType !== InteractionType.Redirect
    ) {
      throw new BrowserConfigurationAuthError(
        'invalid_interaction_type',
        'Invalid interaction type provided to MSAL Interceptor. InteractionType.Popup, InteractionType.Redirect must be provided in the msalInterceptorConfiguration',
      );
    }
  };

  /**
   * Looks up the scopes for the given endpoint from the protectedResourceMap
   * @param endpoint Url of the request
   * @param httpMethod Http method of the request
   * @returns Array of scopes, or null if not found
   *
   */
  getScopesForEndpoint(endpoint: string, httpMethod: string): string[] | null {
    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint = this.location.normalize(endpoint);

    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());

    const matchingProtectedResources = this.matchResourcesToEndpoint(protectedResourcesArray, normalizedEndpoint);

    // Check absolute urls of resources first before checking relative to prevent incorrect matching where multiple resources have similar relative urls
    if (matchingProtectedResources.absoluteResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.absoluteResources,
        httpMethod,
      );
    } else if (matchingProtectedResources.relativeResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.relativeResources,
        httpMethod,
      );
    }

    return null;
  }

  /**
   * Finds resource endpoints that match request endpoint
   * @param protectedResourcesEndpoints
   * @param endpoint
   * @returns
   */
  private matchResourcesToEndpoint(protectedResourcesEndpoints: string[], endpoint: string): any {
    const matchingResources: any = {
      absoluteResources: [],
      relativeResources: [],
    };

    protectedResourcesEndpoints.forEach((key) => {
      // Normalizes and adds resource to matchingResources.absoluteResources if key matches endpoint. StringUtils.matchPattern accounts for wildcards
      const normalizedKey = this.location.normalize(key);
      if (StringUtils.matchPattern(normalizedKey, endpoint)) {
        matchingResources.absoluteResources.push(key);
      }

      // Get url components for relative urls
      const absoluteKey = this.getAbsoluteUrl(key);
      const keyComponents = new UrlString(absoluteKey).getUrlComponents();
      const absoluteEndpoint = this.getAbsoluteUrl(endpoint);
      const endpointComponents = new UrlString(absoluteEndpoint).getUrlComponents();

      // Normalized key should include query strings if applicable
      const relativeNormalizedKey = keyComponents.QueryString
        ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}`
        : this.location.normalize(keyComponents.AbsolutePath);

      // Add resource to matchingResources.relativeResources if same origin, relativeKey matches endpoint, and is not empty
      if (
        keyComponents.HostNameAndPort === endpointComponents.HostNameAndPort &&
        StringUtils.matchPattern(relativeNormalizedKey, absoluteEndpoint) &&
        relativeNormalizedKey !== '' &&
        relativeNormalizedKey !== '/*'
      ) {
        matchingResources.relativeResources.push(key);
      }
    });

    return matchingResources;
  }

  /**
   * Transforms relative urls to absolute urls
   * @param url
   * @returns
   */
  private getAbsoluteUrl(url: string): string {
    const link = this._document.createElement('a');
    link.href = url;
    return link.href;
  }

  /**
   * Finds scopes from first matching endpoint with HTTP method that matches request
   * @param protectedResourceMap Protected resource map
   * @param endpointArray Array of resources that match request endpoint
   * @param httpMethod Http method of the request
   * @returns
   */
  private matchScopesToEndpoint(
    protectedResourceMap: Map<string, (string | ProtectedResourceScopes)[] | null>,
    endpointArray: string[],
    httpMethod: string,
  ): string[] | null {
    const allMatchedScopes: any[][] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint) => {
      const scopesForEndpoint: string[] = [];
      const methodAndScopesArray = protectedResourceMap.get(matchedEndpoint);

      // Return if resource is unprotected
      if (methodAndScopesArray === null) {
        allMatchedScopes.push(null);
        return;
      }

      methodAndScopesArray.forEach((entry) => {
        // Entry is either array of scopes or ProtectedResourceScopes object
        if (typeof entry === 'string') {
          scopesForEndpoint.push(entry);
        } else {
          // Ensure methods being compared are normalized
          const normalizedRequestMethod = httpMethod.toLowerCase();
          const normalizedResourceMethod = entry.httpMethod.toLowerCase();
          // Method in protectedResourceMap matches request http method
          if (normalizedResourceMethod === normalizedRequestMethod) {
            // Validate if scopes comes null to unprotect the resource in a certain http method
            if (entry.scopes === null) {
              allMatchedScopes.push(null);
            } else {
              entry.scopes.forEach((scope) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      });

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length > 0) {
      if (allMatchedScopes.length > 1) {
      }
      // Returns scopes for first matching endpoint
      return allMatchedScopes[0];
    }

    return null;
  }
}
