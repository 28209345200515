import { SERVICE_ENDPOINTS, environment } from 'src/environments/environment';

const SERVICE_EP: string = SERVICE_ENDPOINTS.processController;

export const ApiEndpoints = {
  CREATE_JOB: SERVICE_EP + `/jobs/create`,
  CREATE_AND_EXECUTE_AUTO_JOBS: SERVICE_EP + `/auto_jobs/create`,
  GET_JOB: SERVICE_EP + '/jobs/',
  GET_JOB_BREADCRUMB: SERVICE_EP + '/jobs/breadcrumb/',
  START_JOB: SERVICE_EP + '/jobs/:id/start',
  GET_JOB_STATS: SERVICE_EP + '/job_stats',
  GET_JOB_LIST: SERVICE_EP + '/jobs/list',
  GET_CATEGORY_LIST: SERVICE_EP + `/category`,
  CREATE_EXTRACTION_SCHEMA: SERVICE_EP + `/extraction_schema/create`,
  UPDATE_EXTRACTION_SCHEMA: SERVICE_EP + `/extraction_schema/:id/update`,
  GET_UNUSED_TILS: SERVICE_EP + `/extraction_schema/:id/list_unused_tils`,
  GET_EXTRACTION_SCHEMA: SERVICE_EP + `/extraction_schema`,
  DOWNLOAD_SCHEMA_REPORT: SERVICE_EP + '/extraction_schema/download_report',
  GET_MESSAGE_COUNT: SERVICE_EP + '/get_message_count',
  GET_TEMPLATE_MESSAGE_COUNT: SERVICE_EP + '/get_template_message_count',
  START_EXTRACTION_MANUALLY: SERVICE_EP + '/start_extraction_manually',
  SPAWN_TASKS: SERVICE_EP + '/spawn_tasks',
  GET_JOB_RUN_PROCESS: SERVICE_EP + '/job_run/:id/progress',
  GET_EXTRACTED_DATA_INFO: SERVICE_EP + '/data_extraction/page_list',
  CREATE_TESTS_MODULE_DUPLICATE_JOB: SERVICE_EP + '/create_execute_duplicate_jobs',
  LIST_DUPLICATE_JOBS: SERVICE_EP + '/list_duplicate_jobs',
  RERUN_FILES: SERVICE_EP + '/job/',
  START_COMPARISON_WITH_MASTER_DATA: SERVICE_EP + '/event_num_data_comparison',
  DELETE_JOB: SERVICE_EP + '/jobs/',
  DELETE_EXTRACTION_SCHEMA: SERVICE_EP + '/extraction_schema/',
  DOWNLOAD_BLANK_TEMPLATE: SERVICE_EP + '/extraction_schema/download_template/',
  CREATE_BATCH_JOBS: SERVICE_EP + '/batch-jobs/create',
  GET_PRESIGNED_UPLOAD_URL_BLANK_TEMP: SERVICE_EP + '/extraction_schema/presigned_url',
  UPDATE_JOB_RUN : SERVICE_EP + '/jobs/:id/update/',
  UPDATE_JOB_RUN_FILE : SERVICE_EP + '/job_files/:id/update/',

  // websocket api gateway endpoints
  BASE_WEBSOCKET_URL: environment.baseWebsocketUrl,

  // Notifications
  GET_NOTIFICATIONS: SERVICE_EP + '/notifications',
  DELETE_NOTIFICATION: SERVICE_EP + '/notifications/delete/',
  UPDATE_NOTIFICATION: SERVICE_EP + '/notifications/update/',
};
