import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  effect,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from './core/services/auth/auth.service';
import { BreadcrumbService } from './core/services/breadcrumb.service';
import { DisclaimerService } from './core/services/disclaimer.service';
import { LoaderService } from './core/services/loader/loader.service';
import { NotificationsService } from './core/services/notifications/notifications.service';
import { WebsocketService } from './core/services/websocket.service';
import { DisclaimerComponent } from './feature/disclaimer.component';
import { CellTemplateComponent } from './shared/components/organisms/cell-template/cell-template.component';
import { RouteOutlets } from './shared/enums/global.enum';

@Component({
  selector: 'app-root',
  template: `
    <dige-cell-template></dige-cell-template>

    <div class="se-body">
      @if (!isIframe) {
        <router-outlet></router-outlet>
      }
    </div>
    <ng-template #disclaimerTemplate let-data>
      <de-disclaimer></de-disclaimer>
    </ng-template>
  `,
  styles: `
    .loader-container {
      position: relative;
      z-index: 6;
    }
  `,
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [DisclaimerService, WebsocketService],
  imports: [CellTemplateComponent, RouterOutlet, DisclaimerComponent],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('disclaimerTemplate') disclaimerTemplate;
  @ViewChild(DisclaimerComponent) disclaimerComponent: DisclaimerComponent;

  showAgreeButton = false;
  interval;
  timeSubject = new BehaviorSubject(60);
  timeLeft = 60;
  isIframe;
  websocketService: WebsocketService;
  websocketConnActive = false;
  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private injector: Injector,
    private notificationService: NotificationsService,
    private destroyRef: DestroyRef,
    private disclaimerService: DisclaimerService,
  ) {
    effect((onCleanUp) => {
      onCleanUp(() => null);
      if (this.authService.loginComputed()) this.loginSuccess();
    });
  }
  ngOnInit(): void {
    this.authService.checkIfUserLoggedIn();
    this.authService.authenticationSubjectSubscription();
    this.authService.redirectObservable();
    this.isIframe = window !== window.parent && !window.opener;
    this.routerEventsSubscription();
  }

  ngAfterViewInit(): void {
    this.showDisclaimer();
  }

  loginSuccess() {
    this.showDisclaimer(true);
  }

  showDisclaimer(isLoggedIn?: boolean) {
    this.disclaimerService
      .showDisclaimer(this.disclaimerTemplate, isLoggedIn)
      ?.afterOpened()
      .subscribe(() => {
        this.disclaimerService.afterDisclaimerPopped(this.disclaimerComponent);
      });
  }

  routerEventsSubscription() {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((event: Event): boolean => event instanceof NavigationEnd),
        map((): ActivatedRoute => {
          let route: ActivatedRoute = this.activatedRoute;
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route: ActivatedRoute): boolean => route.outlet === RouteOutlets.PRIMARY),
        tap((route: ActivatedRoute): void => {
          this.breadcrumbService.setBreadCrumbs(route.snapshot.data.breadCrumbs, route);
        }),
      )
      .subscribe();
  }

  initializeWebSocketIfRequiredElseClose(): void {
    this.websocketService = this.injector.get(WebsocketService) as WebsocketService;
    this.websocketService.setConnectionTypeAndConnect();
    this.subscribeToWebsocket();
  }

  subscribeToWebsocket(): void {
    this.websocketService.messages.subscribe((msg: any): void => {
      this.notificationService.newNotificationSubject.next(msg);
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(): void {
    this.websocketService?.messages?.complete();
  }

  ngOnDestroy(): void {
    this.websocketService.messages.complete();
  }
}
