import { Component, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService } from '../core/services/auth/auth.service';
import { DialogService } from '../core/services/dialog/dialog.service';
import { DeButtonComponent } from '../shared/components/atoms/de-button/de-button.component';
import { CookiesUtil } from '../shared/utils/cookie.util';
@Component({
  selector: 'de-disclaimer',
  templateUrl: './disclaimer.component.html',
  imports: [DeButtonComponent, MatTooltip],
})
export class DisclaimerComponent {
  @ViewChild('contentContainer') contentContainer;
  @ViewChild('disclaimerRef') disclaimerRef;
  showAgreeButton = false;

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
  ) {}
  disclaimerAgreed() {
    CookiesUtil.set('disclaimer', 'popup', 1);
    this.dialogService.closeDialog();
  }

  disclaimerDeclined() {
    this.authService.logout();
  }
}
