import { Component, Inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';

@Component({
  selector: 'de-login',
  template: `
    <section>
      <div class="cover-container background">
        <div class="brand-heading col-md-7">
          <img src="/assets/images/SE_Logo_Color_RGB.svg" alt="logo" width="100" class="siemens-image" />
          <h1 class="login-brand-heading">Welcome to Digital Eye</h1>
          <button class="login-btn" (click)="login()">Login via MSAL</button>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./login.component.scss'],
  standalone: true,
})
export class LoginComponent {
  isAuthenticated = false;
  isIframe = false;
  loginDisplay = false;

  constructor(
    private msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  ) {}

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.msalService
        .loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest)
        .subscribe({
          error: (error) => {
            console.error('Login redirect failed:', error);
            if (error.errorCode === 'interaction_in_progress') {
              console.warn('An interaction is already in progress. Please wait.');
            }
          },
        });
    } else {
      this.msalService.loginRedirect().subscribe({
        error: (error) => {
          console.error('Login redirect failed:', error);
        },
      });
    }
  }
}
