import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProcessControllerMicroService } from '../../microservices';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  newNotificationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private processControllerService: ProcessControllerMicroService) {}

  getNotifications() {
    return this.processControllerService.getNotifications();
  }

  deleteNotificationByID(id: number) {
    return this.processControllerService.deleteNotificationById(id);
  }

  updateNotificationById(id: number, payload) {
    return this.processControllerService.updateNotificationById(id, payload);
  }
}
