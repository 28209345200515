import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadCrumbs: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
  breadCrumbs$: Observable<MenuItem[]> = this.breadCrumbs.asObservable();

  constructor() {}

  setBreadCrumbs(data: MenuItem[] = [], route?: ActivatedRoute): void {
    if (!data.length) {
      return;
    }
    const breadcrumb: MenuItem[] = JSON.parse(JSON.stringify(data));
    breadcrumb?.forEach((crumb: MenuItem): void => {
      if (crumb) {
        if (crumb.routerLink) {
          const urlChunks: string[] = crumb.routerLink.split('/');
          for (const chunk of urlChunks) {
            if (chunk.includes(':')) {
              const paramID: string = chunk.replace(':', '');
              const routerParamID: string = route.snapshot.params[paramID];
              crumb.routerLink = crumb.routerLink.replace(`:${paramID}`, routerParamID);
            }
          }
        }
        const labelParams: string[] = crumb.label?.match(/[^{{]+(?=\}})/g);
        if (labelParams) {
          for (const labelParam of labelParams) {
            const routerParamID: string = route.snapshot.params[labelParam.trim()];
            if (routerParamID) {
              crumb.label = crumb.label.replace('{{' + labelParam + '}}', routerParamID);
              crumb.label = crumb.label.replace(/\^/g, '/');
            }
          }
        }
      }
    });
    this.breadCrumbs.next(breadcrumb);
  }

  getBreadCrumbs(): MenuItem[] {
    return this.breadCrumbs.value || [];
  }
}
